<script lang="ts">
  /**
  * The ApplyOrientationForm component is used to display the apply orientation form.
  */
  import { createEventDispatcher } from "svelte";
  import { field, form } from "svelte-forms";
  import { useOrientationStore } from "$lib/utils/orientation-store";
  import { required as requiredValidation } from 'svelte-forms/validators';
  import CtaServicesApiClientSingleton from "$lib/api/CtaServicesApiClientSingleton";
  import type { SubmitOrientationRequestRequest } from '$lib/api/generated/cta-services';
  import { CustomSelect } from "$lib/components/atoms/Field";

  export let loading: boolean;
  $: loading = false;

  const orientationStore = useOrientationStore();

  const ctaService = CtaServicesApiClientSingleton.getInstance();

  const dispatch = createEventDispatcher();

  // form variables

  const callTime = field(
    'callTime',
    null,
    [requiredValidation()],
    { valid: false }
  );

  const callPreference = form(
    callTime
  );

  /**
   * The handleFormSubmit function is used to handle the form submit event.
   * It dispatches the formSubmit event.
   * @returns {void}
   */
  export const handleSubmit = () => {
    loading = true;

    console.log('callPreference', $callPreference);
    callPreference.validate();

    if ($callPreference.valid) {

      let callPreferenceFormReq: SubmitOrientationRequestRequest = orientationStore.getOrientationRequest();
      //inside callPreferenceFormReq there is the orientation object, set the callPreference value with the selected value using spread operator
      callPreferenceFormReq = {
        ...callPreferenceFormReq,
        orientation: {
          ...callPreferenceFormReq.orientation,
          callPreference: $callTime.value
        }
      };
      
      ctaService.then(async (client) => {
        try { 
          const orientationRes = await client.submitOrientationRequest(callPreferenceFormReq);

          if (orientationRes.uid) {
            orientationStore.setIsLastStepSubmitted(true);
            orientationStore.setOccupationChoosen(callPreferenceFormReq.orientation.currentOccupation);
            dispatch('success');
          } 
        } catch (e) {
          console.error("error submitOrientationRequest", e);
        } finally {
          loading = false;
        }
      });
    } else {
      loading = false;
    }
  };
</script>

<!-- svelte-ignore component-name-lowercase -->
<form class="col-span-full" on:submit|preventDefault={handleSubmit}>
  <CustomSelect
    class="col-span-full"
    name="callTime"
    id="callTime"
    label="In quale fascia oraria preferisci essere ricontattato? *"
    bind:value={$callTime.value}
    options={[
      { label: '9:00 - 12:00', value: 'mattina' },
      { label: '12:00 - 15:00', value: 'pomeriggio' },
      { label: '15:00 - 18:00', value: 'sera'},
      { label: 'Qualunque orario', value: 'qualunque orario' }
    ]}
    error={
      $callPreference.hasError('callTime.required')
        ? 'Selezione la fascia oraria che preferisci'
        : null
    }
  />
</form>

